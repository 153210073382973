import React from 'react';
import styled from 'styled-components';
import { Button } from 'fe-appdir-comp-button';
import theme, { rems } from 'fe-appdir-lib-theme';

const LoadMoreButtonContainer = styled.div`
  text-align: center;
  margin: ${theme.spacingXl} auto ${theme.spacingXxl};

  @media (min-width: ${theme.breakpointMd}) {
    margin-bottom: ${theme.spacingXxl} auto ${rems(72)};
  }
`;

const LoadMoreButton = styled(Button)`
  ${(p) =>
    p.isDisabled
      ? `
        &, &:hover {
            pointer-events: none;
            border-color: ${theme.colorGrey80};
            background-color: ${theme.colorGrey95};
            color: ${theme.colorGrey80};
        }
        `
      : null}
`;

const LoadMoreButtonWithContainer = ({ disabled, ...restProps }) => (
  <LoadMoreButtonContainer>
    <LoadMoreButton {...restProps} disabled={disabled} isDisabled={disabled} />
  </LoadMoreButtonContainer>
);

export default LoadMoreButtonWithContainer;
