import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from 'fe-appdir-lib-theme';
import routes, { renderRoute } from './config/routes';
import ScrollToTop from './ScrollToTop';
import Head from './config/Head';
import SkipToMainContent from './styled/SkipToMainContent';
import AppHeader from 'fe-appdir-comp-header';
import { Route, Switch } from 'react-router-dom';
import {
  NavTrackIds,
  SearchTrackIds,
  logCustomEvent,
  logVirtualPageView,
} from 'fe-appdir-lib-analytics';
import { useHideDrift } from './hooks';

export const CURATED_COLLECTIONS_ID = 11;
export const FEATURED_CATEGORY_ID = '104';

const mainContentId = 'mainContent';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Open Sans", sans-serif;
    src: url("../fonts/Operator-Mono.ttf");
  }

  body {
    margin: 0;
  }
`;

const AppDir = () => {
  useHideDrift();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ScrollToTop>
        <Head />
        <SkipToMainContent href={`#${mainContentId}`}>
          Skip to main content
        </SkipToMainContent>
        <AppHeader
          gaTrackIds={{ NavTrackIds, SearchTrackIds }}
          logCustomEvent={logCustomEvent}
        />
        <div id={mainContentId}>
          <Switch>{routes.map((route, i) => renderRoute(route))}</Switch>
          <Route path="/" render={logVirtualPageView} />
        </div>
      </ScrollToTop>
    </ThemeProvider>
  );
};

export { routes };
export default AppDir;
