import React from 'react';
import styled from 'styled-components';
import theme from 'fe-appdir-lib-theme';
import PropTypes from 'prop-types';

/*
 * Notes:
 * - To prevent an horizontal scrollbar on narrow screens on IE,
 *   you must add overflow-x: hidden to the body
 * - columnsGap, rowGap, and their -Md variants for large screens must be in px or rems or %
 *   (they can't be just numbers, otherwise the width calc will fail)
 */
const GRID_PROP_TYPES = {
  children: PropTypes.node,
  columns: PropTypes.number,
  columnGap: PropTypes.string,
  rowGap: PropTypes.string,
  columnsMd: PropTypes.number,
  columnGapMd: PropTypes.string,
  rowGapMd: PropTypes.string,
};

const GRID_DEFAULT_PROPS = {
  columns: 1,
  columnGap: '-0px',
  rowGap: theme.spacingMd,
  columnsMd: 4,
  columnGapMd: theme.spacingLg,
  rowGapMd: theme.spacingLg,
};

/* Grid component */

const StyledGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -${(p) => p.columnGap};
  margin-bottom: -${(p) => p.rowGap};

  @media (min-width: ${theme.breakpointMd}) {
    margin-right: -${(p) => p.columnGapMd};
    margin-bottom: -${(p) => p.rowGapMd};
  }

  @supports (display: grid) {
    display: grid;
    margin: 0;
    grid-template-rows: auto;
    grid-template-columns: repeat(
      ${(p) => p.columns},
      ${(p) =>
        `calc((100% - (${p.columns - 1} * ${p.columnGap})) / ${p.columns})`}
    );
    grid-row-gap: ${(p) => p.rowGap};
    grid-column-gap: ${(p) => p.columnGap};

    @media (min-width: ${theme.breakpointMd}) {
      grid-template-columns: repeat(
        ${(p) => p.columnsMd},
        ${(p) =>
          `calc((100% - (${p.columnsMd - 1} * ${p.columnGapMd})) / ${
            p.columnsMd
          })`}
      );
      grid-row-gap: ${(p) => p.rowGapMd};
      grid-column-gap: ${(p) => p.columnGapMd};
    }
  }
`;

// We need to pass the positioning props down to GridItems
const Grid = ({ children, ...props }) => (
  <StyledGrid {...props}>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...props,
      }),
    )}
  </StyledGrid>
);

Grid.propTypes = GRID_PROP_TYPES;
Grid.defaultProps = GRID_DEFAULT_PROPS;

/* GridItem component */

const ITEM_PROP_TYPES = {
  ...GRID_PROP_TYPES,
  span: PropTypes.number,
};

const ITEM_DEFAULT_PROPS = {
  ...GRID_DEFAULT_PROPS,
  span: 1,
};

// A column should never be allowed to span more than the current number of columns in the grid
const StyledGridItem = styled.div`
  margin-right: ${(p) => p.columnGap};
  margin-bottom: ${(p) => p.rowGap};
  width: calc(
    (100% - (${(p) => p.columns} * ${(p) => p.columnGap})) / ${(p) => p.columns}
  );

  &:empty {
    display: none;
  }

  @media (min-width: ${theme.breakpointMd}) {
    margin-right: ${(p) => p.columnGapMd};
    margin-bottom: ${(p) => p.rowGapMd};
    width: calc(
      ${(p) => Math.min(p.span, p.columnsMd)} *
        (
          (100% - (${(p) => p.columnsMd} * ${(p) => p.columnGapMd})) /
            ${(p) => p.columnsMd}
        ) +
        (${(p) => Math.min(p.span, p.columnsMd) - 1} * ${(p) => p.columnGapMd})
    );
  }

  @supports (display: grid) {
    margin: 0;
    width: auto;
    grid-column: span ${(p) => Math.min(p.span, p.columns)};

    @media (min-width: ${theme.breakpointMd}) {
      grid-column: span ${(p) => Math.min(p.span, p.columnsMd)};
    }
  }
`;

const GridItem = ({ ...props }) => <StyledGridItem {...props} />;

GridItem.propTypes = ITEM_PROP_TYPES;
GridItem.defaultProps = ITEM_DEFAULT_PROPS;

export { Grid, GridItem };
