import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { store, getPaginatedApps, resetPagination } from 'fe-appdir-data-app';
import { ViewTrackIds } from 'fe-appdir-lib-analytics';
import TitleBar from 'fe-appdir-comp-titlebar';
import Container from '../styled/Container';
import { Grid, GridItem } from '../styled/Grid';
import { AppCard } from 'fe-appdir-comp-card';
import LoadMoreButton from '../styled/LoadMoreButton';
import theme, { rems } from 'fe-appdir-lib-theme';

const GridWrapper = styled.div`
  margin-top: ${theme.spacingXxl};
  margin-bottom: ${theme.spacingXl};

  @media (min-width: ${theme.breakpointMd}) {
    margin-top: ${rems(72)};
  }
`;

/**
 * View for `/apps` route
 */
const StatelessApps = ({
  appIds,
  apps,
  paginationPage,
  haveLoadedAllPages,
  isFetchingPaginatedApps,
}) => (
  <React.Fragment>
    <Helmet>
      <title>
        Best Social Media Apps to Customize Your Hootsuite Dashboard
      </title>
      <meta name="keywords" content="Best Social Media Apps" />
      <meta
        name="description"
        content="Browse all the apps in our directory to find ones that suit the way you work."
      />
    </Helmet>
    <TitleBar
      title="All Apps"
      subtitle="Browse all the apps in our directory to find ones that suit the way you work."
      layout="center"
    />
    <Container>
      {appIds.length > 0 ? (
        <GridWrapper>
          <Grid>
            {appIds.map((appId, index) =>
              apps[appId] ? (
                <GridItem key={index}>
                  <AppCard app={apps[appId]} />
                </GridItem>
              ) : null,
            )}
          </Grid>
        </GridWrapper>
      ) : null}
      <LoadMoreButton
        type="standard"
        size="lg"
        onClick={() => getPaginatedApps(paginationPage)}
        isFetching={isFetchingPaginatedApps}
        gaTrackId={ViewTrackIds.LOAD_MORE_APPS}
        disabled={haveLoadedAllPages}
      >
        Load More Apps
      </LoadMoreButton>
    </Container>
  </React.Fragment>
);

const Apps = compose(
  fulfill(() => {
    resetPagination();
    getPaginatedApps(1);
  }),

  connect(store, (state) => ({
    appIds: state.apps.idsByPage.reduce(
      (accumulator, current) => accumulator.concat(current),
      [],
    ),
    apps: state.apps.byId,
    paginationPage: state.paginationPage,
    haveLoadedAllPages: state.haveLoadedAllPages,
    isFetchingPaginatedApps: state.isFetchingPaginatedApps,
  })),
)(StatelessApps);

export default Apps;
