import styled from 'styled-components';
import theme from 'fe-appdir-lib-theme';

const MAX_SECTION_WIDTH = '940px';

const Container = styled.div`
  max-width: ${MAX_SECTION_WIDTH};
  margin: 0 auto;
  padding: 0 ${theme.spacingMd};
`;

export default Container;
