import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import theme from 'fe-appdir-lib-theme';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { notNull } from 'fe-hoc-not-null';
import {
  getAppsByCategoryId,
  getCategories,
  getCategoryIdsByCategoryTypeId,
  getCategoryUrl,
  store,
} from 'fe-appdir-data-app';
import { ViewTrackIds } from 'fe-appdir-lib-analytics';
import { CURATED_COLLECTIONS_ID, FEATURED_CATEGORY_ID } from '../App';
import TitleBar from 'fe-appdir-comp-titlebar';
import Carousel from 'fe-appdir-comp-carousel';
import { AppCard } from 'fe-appdir-comp-card';
import TitleCard from 'fe-appdir-comp-titlecard';
import { MiniLogoCard } from 'fe-appdir-comp-mini-card';
import CtaSection from 'fe-appdir-comp-cta-section';
import CtaCard from 'fe-appdir-comp-cta-card';
import { CuratedCollection } from 'fe-appdir-comp-curated-collection';
import { ButtonLink } from 'fe-appdir-comp-button';
import Icon from '@fp-icons/icon-base';
import ArrowRightSmall from '@fp-icons/arrow-right-small';
import Container from '../styled/Container';
import { Link } from 'fe-appdir-comp-link';
import InvisibleLink from '../styled/InvisibleLink';
import BasicCard from '../styled/BasicCard';
import BoldText from '../styled/BoldText';
import { Tiles } from '../img/backgrounds.js';
import {
  brandProtectionSvg,
  campaignEffectivenessSvg,
  socialIntegrationSvg,
} from '../img/svg.js';
import ctaCardImg from '../img/computer-hands.jpg';

const H3 = styled.h3`
  margin-top: ${theme.spacingXxl};
  margin-bottom: ${theme.spacingXs};
  text-align: center;
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${theme.fontSizeH2};
  font-weight: ${theme.fontWeightSemibold};
  color: ${theme.colorBase};
`;

const H4 = styled.h4`
  font-family: ${theme.fontFamilyPrimary};
  text-align: center;
  font-weight: ${theme.fontWeightNormal};
  font-size: ${theme.fontSizeBase};
  margin: 0 0 ${theme.spacingLg};
`;

const StyledLink = styled(Link)`
  font-family: ${theme.fontFamilyPrimary};
  font-weight: ${theme.fontWeightBold};
  color: ${theme.colorBlack};
  text-decoration: none;
  transition: 0.2s color;

  &:hover,
  &:focus {
    color: ${theme.colorBrandPrimary};
  }

  &:active {
    color: ${theme.colorBrandPrimary};
  }
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
`;

const CtaCardImg = styled.img`
  min-height: 100%;
`;

const CampaignEffectivenessImgContainer = styled.div`
  & svg {
    width: auto;
    max-height: 175px;
  }

  @media (min-width: ${theme.breakpointMd}) {
    position: absolute;
    top: -60px;
    right: -25px;

    & svg {
      width: auto;
      max-height: none;
    }
  }
`;

const shouldRender = (app) =>
  app.hasOwnProperty('billing') &&
  app.billing.hasOwnProperty('billingInterval') &&
  app.billing.hasOwnProperty('prices') &&
  app.billing.prices.length > 0 &&
  app.billing.prices[0].hasOwnProperty('currency') &&
  app.billing.prices[0].hasOwnProperty('price') &&
  app.hasOwnProperty('primaryCategory') &&
  app.primaryCategory.hasOwnProperty('name') &&
  app.hasOwnProperty('shortDescription') &&
  app.hasOwnProperty('iconUrl') &&
  app.hasOwnProperty('installUrl') &&
  app.hasOwnProperty('name') &&
  app.hasOwnProperty('slug');

/**
 * View for `/` route
 */
const StatelessHome = ({
  featuredAppIds,
  apps,
  categories,
  curatedCollectionCategoryIds,
  appsByCategoryId,
  images = [
    brandProtectionSvg,
    socialIntegrationSvg,
    (i) => (
      <CampaignEffectivenessImgContainer>
        {campaignEffectivenessSvg(i)}
      </CampaignEffectivenessImgContainer>
    ),
  ],
  borderColors = ['blue', 'purple', 'green'],
}) => (
  <React.Fragment>
    <Helmet>
      <title>Social Media Apps: Hootsuite App Directory</title>
      <meta name="keywords" content="Social Media Apps" />
      <meta
        name="description"
        content="Find social media apps and extensions that integrate with Hootsuite’s dashboard to customize and enhance your social media activities."
      />
    </Helmet>
    <TitleBar
      title="Make your (work) life easier"
      subtitle="Tools to help you streamline your processes, access better data, and collaborate more efficiently."
      layout="center"
      bgImage={Tiles}
    />
    {featuredAppIds.length > 0 ? (
      <Container>
        <H3>Featured Apps</H3>
        <Carousel>
          {featuredAppIds.map((appId) =>
            shouldRender(apps[appId]) ? (
              <AppCard key={appId} app={apps[appId]} />
            ) : null,
          )}
        </Carousel>
      </Container>
    ) : null}
    <Container>
      <H3>Curated Collections</H3>
      <H4>
        Hand-picked apps recommended by our talented team of social media
        experts.
      </H4>
      {curatedCollectionCategoryIds.map((collectionId, i) => {
        const category = categories[collectionId];
        return (
          appsByCategoryId &&
          appsByCategoryId[collectionId] && (
            <CuratedCollection
              key={i}
              header={
                <TitleCard
                  heading={category.name}
                  graphic={() => images[i % images.length](i)}
                  borderColor={borderColors[i % borderColors.length]}
                  body={category.description}
                  link={() => (
                    <StyledLink
                      to={getCategoryUrl(category)}
                      data-ga-track-id={ViewTrackIds.CURATED_COLLECTION}
                      data-ga-track-info={category.slug}
                    >
                      {`View all ${category.name}${
                        category.name.match(/apps/i) ? '' : ' Apps'
                      }`}{' '}
                      <StyledIcon
                        glyph={ArrowRightSmall}
                        size={18}
                        fill={'currentColor'}
                        title={'Right Arrow Icon'}
                      />
                    </StyledLink>
                  )}
                />
              }
              items={appsByCategoryId[collectionId].slice(0, 6).map((appId) => {
                const app = apps[appId];
                return (
                  <MiniLogoCard
                    title={app.name}
                    logos={{ twoX: app.iconUrl }}
                    linkTo={`/apps/${app.slug}`}
                    gaTrackId={ViewTrackIds.CURATED_COLLECTION_APP}
                    gaTrackInfo={app.slug}
                  />
                );
              })}
            />
          )
        );
      })}
      <InvisibleLink to="/categories" gaTrackId={ViewTrackIds.VIEW_ALL_APPS}>
        <BasicCard>
          <BoldText>See more apps and categories</BoldText>
          <p>
            Haven’t found what you’re looking for? Visit our Categories page for
            more.
          </p>
        </BasicCard>
      </InvisibleLink>
    </Container>
    <CtaCard
      graphic={() => (
        <CtaCardImg src={ctaCardImg} alt="pair of hands typing on a laptop" />
      )}
      heading="Build your own app"
      body="Got an app&mdash;or working on one&mdash;that you want to see in our directory? Apply for developer access today."
      cta={() => (
        <ButtonLink
          type="default"
          href="https://developer.hootsuite.com"
          aria-label="Learn more about developing Hootsuite applications"
        >
          Start Building Today
        </ButtonLink>
      )}
    />
    <CtaSection
      heading="Take Hootsuite for a test drive"
      ctas={() => (
        <React.Fragment>
          <ButtonLink
            type="ghost"
            href="https://hootsuite.com/plans"
            gaTrackId={ViewTrackIds.COMPARE_PLANS}
          >
            Compare Plans
          </ButtonLink>
          <ButtonLink type="standard" href="https://hootsuite.com/request-demo">
            Request a Demo
          </ButtonLink>
        </React.Fragment>
      )}
    />
  </React.Fragment>
);

const Home = compose(
  fulfill(() => {
    getAppsByCategoryId(FEATURED_CATEGORY_ID);
    getCategories().then(() => {
      const curatedCollectionCategoryIds = getCategoryIdsByCategoryTypeId(
        CURATED_COLLECTIONS_ID,
      ).filter((categoryId) => categoryId !== FEATURED_CATEGORY_ID);
      curatedCollectionCategoryIds.forEach((categoryId) => {
        getAppsByCategoryId(categoryId);
      });
    });
  }),
  connect(store, (state) => ({
    featuredAppIds: state.apps.idsByCategoryId[FEATURED_CATEGORY_ID],
    apps: state.apps.byId,
    categories: state.categories.byId,
    curatedCollectionCategoryIds: getCategoryIdsByCategoryTypeId(
      CURATED_COLLECTIONS_ID,
    ).filter((categoryId) => categoryId !== FEATURED_CATEGORY_ID),
    appsByCategoryId: state.apps.idsByCategoryId,
  })),
  notNull(
    [
      'featuredAppIds',
      'categories',
      'apps',
      'curatedCollectionCategoryIds',
      'appsByCategoryId',
    ],
    () => null,
  ),
)(StatelessHome);

export default Home;
