import styled from 'styled-components';
import theme, { visuallyHidden, notVisuallyHidden } from 'fe-appdir-lib-theme';

// The skip link is initially hidden, but becomes visible when focused
// (inspired by Google's treatment of "skip to main content" link in search results)
const SkipToMainContent = styled.a`
  ${visuallyHidden()}

  &:focus {
    ${notVisuallyHidden()}
    position: fixed;
    top: ${theme.headerHeight};
    background-color: ${theme.colorWhite};
    padding: ${theme.spacingSm};
  }
`;

export default SkipToMainContent;
