import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { notNull } from 'fe-hoc-not-null';
import {
  getCategories,
  getCategoryTypes,
  getCategoryUrl,
  store,
} from 'fe-appdir-data-app';
import { ViewTrackIds } from 'fe-appdir-lib-analytics';
import TitleBar from 'fe-appdir-comp-titlebar';
import { MiniCard } from 'fe-appdir-comp-mini-card';
import theme from 'fe-appdir-lib-theme';
import Icon from '@fp-icons/icon-base';
import Building from '@fp-icons/emblem-building';
import Tag from '@fp-icons/action-tag';
import Container from '../styled/Container';
import BasicCard from '../styled/BasicCard';
import BoldText from '../styled/BoldText';
import { Grid, GridItem } from '../styled/Grid';
import InvisibleLink from '../styled/InvisibleLink';
import { Tiles } from '../img/backgrounds.js';

const APP_BY_DISCOVER_ID = '11';
const APP_BY_CATEGORY_ID = '12';
const APP_BY_INDUSTRIES_ID = '13';

const Heading = styled.div`
  text-align: center;
  margin-top: ${theme.spacingXxl};
  margin-bottom: ${theme.spacingLg};

  @media (min-width: ${theme.breakpointMd}) {
    margin-top: 56px;
    margin-bottom: ${theme.spacingXl};
  }
`;

// H2 must be inline with vertical-align: middle for the SvgIcon to be properly aligned
const H2 = styled.h2`
  font-family: ${theme.fontFamilyPrimary};
  font-weight: ${theme.fontWeightSemibold};
  font-size: ${theme.fontSizeH3};
  color: ${theme.colorBase};
  margin: 0;
  display: inline;
  vertical-align: middle;

  @media (min-width: ${theme.breakpointMd}) {
    font-size: ${theme.fontSizeH2};
  }
`;

const HeadingIcon = styled.span`
  margin-right: 12px;
  margin-top: 5px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  @media (min-width: ${theme.breakpointMd}) {
    margin-right: ${theme.spacingMd};
  }
`;

/**
 * View for `/categories` route
 */
const StatelessCategories = ({
  categoryIds,
  categories,
  categoryTypeIds,
  categoryTypes,
}) => (
  <>
    <Helmet>
      <title>
        Categories of Social Media Apps: Search by Industry or {`Function`}
      </title>
      <meta
        name="description"
        content="View different categories of social media apps in Hootsuite’s App Directory to find apps that meet your specific content needs."
      />
    </Helmet>
    <TitleBar
      title="All Categories"
      subtitle="Search by industry or function to find apps to help you get things done."
      layout="center"
      bgImage={Tiles}
    />
    <Container>
      <Heading>
        <HeadingIcon>
          <Icon
            glyph={Tag}
            size={24}
            fill={theme.colorBase}
            title={'Tag Icon'}
          />
        </HeadingIcon>
        <H2>Essential Apps</H2>
      </Heading>
      <Grid>
        {categoryIds
          .filter(
            (categoryId) =>
              !![APP_BY_CATEGORY_ID, APP_BY_DISCOVER_ID].includes(
                categories[categoryId].categoryTypeId,
              ),
          )
          .map((categoryId) => {
            const category = categories[categoryId];

            return (
              <GridItem key={category.id}>
                <MiniCard
                  title={category.name}
                  textPadding={theme.spacingLg}
                  key={category.id}
                  linkTo={getCategoryUrl(category)}
                  gaTrackId={ViewTrackIds.CATEGORY}
                  gaTrackInfo={category.slug}
                />
              </GridItem>
            );
          })}
      </Grid>
      <Heading>
        <HeadingIcon>
          <Icon
            glyph={Building}
            size={24}
            fill={theme.colorBase}
            title={'Building Icon'}
          />
        </HeadingIcon>
        <H2>Apps by Industry</H2>
      </Heading>
      <Grid>
        {categoryIds
          .filter(
            (categoryId) =>
              !(categories[categoryId].categoryTypeId !== APP_BY_INDUSTRIES_ID),
          )
          .map((categoryId) => {
            const category = categories[categoryId];

            return (
              <GridItem key={category.id}>
                <MiniCard
                  title={category.name}
                  textPadding={theme.spacingLg}
                  key={category.id}
                  linkTo={getCategoryUrl(category)}
                  gaTrackId={ViewTrackIds.CATEGORY}
                  gaTrackInfo={category.slug}
                />
              </GridItem>
            );
          })}
      </Grid>
      <InvisibleLink to="/apps" gaTrackId={ViewTrackIds.VIEW_ALL_APPS}>
        <BasicCard>
          <BoldText>View All Apps</BoldText>
          <span>Want to see more? Check out all our apps.</span>
        </BasicCard>
      </InvisibleLink>
    </Container>
  </>
);

// Consider fetching only the categoryTypes we need (= all but curated collections)
const Categories = compose(
  fulfill(() => {
    getCategoryTypes();
    getCategories({ sortField: 'name', sortOrder: 'asc' });
  }),

  connect(store, (state) => ({
    categoryIds: state.categories.allIds.sort((a, b) =>
      state.categories.byId[a].name < state.categories.byId[b].name ? -1 : 1,
    ),
    categories: state.categories.byId,
    categoryTypeIds: state.categoryTypes.allIds,
    categoryTypes: state.categoryTypes.byId,
  })),

  notNull(
    ['categoryIds', 'categories', 'categoryTypeIds', 'categoryTypes'],
    () => null,
  ),
)(StatelessCategories);

export default Categories;
