import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Home from '../views/Home';
import Apps from '../views/Apps';
import App from '../views/App';
import Categories from '../views/Categories';
import Category from '../views/Category';
import SearchResults from '../views/SearchResults';
import Sitemap from '../views/Sitemap';
import legacyRedirects from './legacy-redirects';
import PageNotFound from '../views/PageNotFound';

const getPath = (route) => route.path;

const getRouteProps = (route) => ({
  ...route,
  key: route.path,
  path: getPath(route),
});

const getRedirectProps = (redirect) => ({
  ...redirect,
  key: redirect.from,
});

export const renderRoute = (route) =>
  route.hasOwnProperty('redirect') && route.redirect ? (
    <Redirect {...getRedirectProps(route)} />
  ) : (
    <Route {...getRouteProps(route)} />
  );

const routes = [
  // Rewrite index-appended URLs to main path
  {
    from: '*/index.html',
    redirect: true,
    to: '*/',
  },
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/apps',
    component: Apps,
    exact: true,
  },
  {
    path: '/apps/:appSlug',
    component: App,
    exact: false,
  },
  {
    path: '/categories',
    component: Categories,
    exact: true,
  },
  {
    path: '/categories/:categorySlug',
    component: Category,
    exact: true,
  },
  {
    path: '/search',
    component: SearchResults,
    exact: false,
  },
  // Only show sitemap for prerender
  {
    path: '/sitemap',
    component:
      window && window.navigator && window.navigator.userAgent === 'ReactSnap'
        ? Sitemap
        : PageNotFound,
    exact: true,
  },
  // Legacy redirects
  ...legacyRedirects.map((redirect) => ({ redirect: true, ...redirect })),
  // If path is not listed, go to 404 page (This should always be at the end of the array)
  {
    path: '',
    component: PageNotFound,
    exact: false,
  },
];

export default routes;
