import React from 'react';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { notNull } from 'fe-hoc-not-null';
import { store, getApps, getCategories } from 'fe-appdir-data-app';

const StatelessSitemap = ({ apps, categories }) => (
  <ul>
    {apps.map((app) => <li><a href={`/apps/${app.slug}`}>{app.name}</a></li>)}
    {categories.map((category) => <li><a href={`/categories/${category.slug}`}>{category.name}</a></li>)}
  </ul>
);

const Sitemap = compose(
  fulfill(() => {
    getApps({pageSize: 999});
    getCategories();
  }),
  connect(
    store,
    (state) => ({
      apps: state.apps.allIds.map((appId) => state.apps.byId[appId]),
      categories: state.categories.allIds.map((categoryId) => state.categories.byId[categoryId]),
    })
  ),
  notNull(['apps', 'categories']),
)(StatelessSitemap)

export default Sitemap;
