import styled from 'styled-components';
import theme from 'fe-appdir-lib-theme';

const BoldText = styled.p`
  font-weight: ${theme.fontWeightSemibold};
  margin-top: 0;
  margin-bottom: 6px;
`;

export default BoldText;
