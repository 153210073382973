import { useState, useEffect } from 'react';

// Max time we should be waiting for Drift, before we call it a day and stop waiting
const TIMEOUT = 10000;
// Number of milliseconds to run the interval for
const INTERVAL = 100;

export const useHideDrift = () => {
  const [driftHidden, setDriftHidden] = useState(false);

  useEffect(() => {
    let timer = 0;
    const interval = setInterval(() => {
      if (window.drift && window.drift.api && window.drift.api.widget) {
        window.drift.on('ready', function() {
          window.drift.api.widget.hide();
          setDriftHidden(true);
        });
      }
      timer += INTERVAL;

      if (timer >= TIMEOUT) {
        clearInterval(interval);
      }
    }, INTERVAL);

    if (driftHidden) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [driftHidden]);
};
