import React from 'react';
import styled from 'styled-components';

const rems = (pixels) => `${(pixels / 16).toFixed(3)}rem`;

// styles copied from 404.twig in website-craft
const Container = styled.div`
  background-color: #f0f0ef;
`;

const Section = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: table;
  max-width: 970px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${() => rems(16)};
  padding-right: ${() => rems(16)};
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial;
`;

const H1 = styled.h1`
  font-size: ${() => rems(54)};
  margin-top: 0;
  margin-bottom: ${() => rems(24)};
  font-weight: 400;
`;

const H3 = styled.h3`
  font-size: ${() => rems(30)};
  margin-bottom: ${() => rems(24)};
  font-weight: 700;
`;

const Body = styled.div`
  line-height: 1.5;
  margin-bottom: ${() => rems(24)};
`;

const Link = styled.a`
  color: #2f6b9a;
  text-decoration: none;
  &:hover {
    color: #5989ae;
  }
`;

const PageNotFound = () => (
  <Container>
    <Section>
      <br />
      <H1>404</H1>
      <H3>The page you are looking for can't be found.</H3>
      <Body>
        Whoops, we cannot find the page you requested. <br /> Perhaps you would
        like to return to the <Link href="/">homepage</Link> and try again?
      </Body>
    </Section>
  </Container>
);
export default PageNotFound;
