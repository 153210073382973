import React from 'react';
import styled from 'styled-components';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { notNull } from 'fe-hoc-not-null';
import { fulfill } from 'fe-hoc-fulfill';
import { CURATED_COLLECTIONS_ID, FEATURED_CATEGORY_ID } from '../App';
import {
  store,
  getCategories,
  getAppsByCategoryId,
  getCategoryIdsByCategoryTypeId,
} from 'fe-appdir-data-app';
import Container from '../styled/Container';
import { AppCard } from 'fe-appdir-comp-card';
import theme from 'fe-appdir-lib-theme';
import { Grid, GridItem } from '../styled/Grid';

const Wrapper = styled(Container)`
  margin-bottom: ${theme.spacingXl};
`;

const H2 = styled.h2`
  margin: ${theme.spacingXl} 0 0;
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${theme.fontSizeH2};
  font-weight: ${theme.fontWeightSemibold};
  text-align: center;

  @media (min-width: ${theme.breakpointMd}) {
    margin: ${theme.spacingXxl} 0;
    font-size: ${theme.fontSizeHero};
  }
`;

const H3 = styled.h3`
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${theme.fontSizeH3};
  font-weight: ${theme.fontWeightNormal};
  text-align: center;

  @media (min-width: ${theme.breakpointMd}) {
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
    font-size: ${theme.fontSizeH2};
    font-weight: ${theme.fontWeightSemibold};
  }
`;

const StyledGrid = styled(Grid)`
  @media (max-width: ${theme.breakpointMd}) {
    padding: 0 ${theme.spacingLg};
  }
`;

const StatelessSearchResults = ({ searchQuery, apps, searchResultsIds }) => (
  <Wrapper>
    <H2>
      Search Results for <strong>&apos;{searchQuery}&apos;</strong>
    </H2>
    {searchResultsIds.length > 0 ? (
      <StyledGrid>
        {searchResultsIds.map((appId) => (
          <GridItem>
            <AppCard app={apps[appId]} />
          </GridItem>
        ))}
      </StyledGrid>
    ) : (
      <H3>
        Oh no! We couldn&apos;t find any results of what you were looking for.
      </H3>
    )}
  </Wrapper>
);

const SearchResults = compose(
  fulfill(() => {
    getAppsByCategoryId(FEATURED_CATEGORY_ID);
    getCategories().then(() => {
      const curatedCollectionCategoryIds = getCategoryIdsByCategoryTypeId(
        CURATED_COLLECTIONS_ID,
      );
      curatedCollectionCategoryIds.forEach((categoryId) => {
        getAppsByCategoryId(categoryId);
      });
    });
  }),
  connect(store, (state) => ({
    searchQuery: state.searchQuery,
    apps: state.apps.byId,
    searchResultsIds: state.apps.searchResultsIds,
  })),
  notNull(['apps', 'searchQuery', 'searchResultsIds'], () => null),
)(StatelessSearchResults);

export default SearchResults;
