const legacyRedirects = [
  // Legacy app redirects
  {
    from: '/(\\d{1,3})/:appSlug',
    to: '/apps/:appSlug',
  },
  { from: '/(\\d{1,3})(/)?', to: '/apps' },
  // Start Category redirects
  { from: '/analytics', to: '/categories/analytics-social-listening' },
  {
    from: '/collaboration',
    to: '/categories/collaboration-project-management',
  },
  { from: '/compliance', to: '/categories/compliance' },
  { from: '/content', to: '/categories/find-content' },
  {
    from: '/crm',
    to: '/categories/crm-marketing-automation',
  },
  {
    from: '/customer-service',
    to: '/categories/customer-support-engagement',
  },
  { from: '/ecommerce', to: '/categories/ecommerce' },
  {
    from: '/email-marketing',
    to: '/categories/crm-marketing-automation',
  },
  {
    from: '/facebook',
    to: '/categories/social-networks-blogs',
  },
  { from: '/featured', to: '/' },
  { from: '/free', to: '/' },
  {
    from: '/instagram',
    to: '/categories/social-networks-blogs',
  },
  {
    from: '/marketing-automation',
    to: '/categories/crm-marketing-automation',
  },
  { from: '/new', to: '/' },
  {
    from: '/social-campaigns',
    to: '/categories/ads-campaigns',
  },
  {
    from: '/social-networks',
    to: '/categories/ads-campaigns',
  },
  {
    from: '/twitter',
    to: '/categories/social-networks-blogs',
  },
  {
    from: '/video-marketing',
    to: '/categories/audio-video-sharing',
  },
  {
    from: '/youtube',
    to: '/categories/audio-video-sharing',
  },
];

export default legacyRedirects;
