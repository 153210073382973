import styled from 'styled-components';
import theme, { rems } from 'fe-appdir-lib-theme';

const BasicCard = styled.div`
  background-color: ${theme.colorGrey20};
  box-shadow: ${theme.boxShadowCard};
  color: ${theme.colorWhite};
  font-family: ${theme.fontFamilyPrimary};
  font-size: ${theme.fontSizeBase};
  padding: ${theme.spacingXl};
  text-align: center;
  margin: ${theme.spacingXxl} 0;

  @media (min-width: ${theme.breakpointMd}) {
    margin: calc(${theme.spacingXxl} * 2) 0;
  }

  & h4 {
    font-weight: ${theme.fontWeightSemibold};
    margin-top: 0;
    margin-bottom: ${rems(6)};
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export default BasicCard;
