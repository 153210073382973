import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import axios from 'fe-axios';
import theme from 'fe-appdir-lib-theme';
import { compose } from 'fe-hoc-compose';
import { connect } from 'fe-hoc-connect';
import { fulfill } from 'fe-hoc-fulfill';
import { notNull } from 'fe-hoc-not-null';
import {
  store,
  getCategoryBySlug,
  getFeaturedApps,
  getPaginatedCategoryApps,
  selectCategoryBySlug,
  selectFeaturedCategoryAppsByCategorySlug,
  resetPagination,
} from 'fe-appdir-data-app';
import { ViewTrackIds } from 'fe-appdir-lib-analytics';
import Container from '../styled/Container';
import TitleBar from 'fe-appdir-comp-titlebar';
import { Tiles } from '../img/backgrounds.js';
import Carousel from 'fe-appdir-comp-carousel';
import { AppCard } from 'fe-appdir-comp-card';
import { Grid, GridItem } from '../styled/Grid';
import LoadMoreButton from '../styled/LoadMoreButton';

const H2 = styled.h2`
  font-family: ${theme.fontFamilyPrimary};
  font-weight: ${theme.fontWeightSemibold};
  font-size: ${theme.fontSizeH3};
  color: ${theme.colorBase};
  text-align: center;
  margin-top: ${theme.spacingXl};
  margin-bottom: ${theme.spacingLg};

  @media (min-width: ${theme.breakpointMd}) {
    font-size: ${theme.fontSizeH2};
    text-align: left;
    margin-top: ${theme.spacingXxl};
    margin-bottom: ${theme.spacingMd};
  }
`;

const GridWrapper = styled.div`
  margin-top: ${theme.spacingXl};
  margin-bottom: ${theme.spacingXl};

  @media (min-width: ${theme.breakpointMd}) {
    margin-top: ${theme.spacingXxl};
  }
`;

/**
 * View for `/categories/:slug` route
 */
const StatelessCategory = ({
  category,
  featuredApps,
  apps,
  paginationPage,
  haveLoadedAllPages,
  isFetchingPaginatedApps,
}) => (
  <React.Fragment>
    <Helmet>
      <title>{category.name}</title>
      <meta name="description" content={category.description} />
    </Helmet>
    <TitleBar
      title={category.name}
      subtitle={category.description}
      layout="center"
      bgImage={Tiles}
    />
    {featuredApps.length > 0 ? (
      <Container>
        <H2>Featured</H2>
        <Carousel>
          {featuredApps.map((featuredApp) => (
            <AppCard app={featuredApp} />
          ))}
        </Carousel>
      </Container>
    ) : null}
    <Container>
      {apps.length > 0 ? (
        <GridWrapper>
          <Grid>
            {apps.map((app) => (
              <GridItem key={app.id}>
                <AppCard app={app} />
              </GridItem>
            ))}
          </Grid>
        </GridWrapper>
      ) : null}
      <LoadMoreButton
        type="standard"
        size="lg"
        onClick={() => {
          getPaginatedCategoryApps(paginationPage, category.id);
        }}
        isFetching={isFetchingPaginatedApps}
        gaTrackId={ViewTrackIds.LOAD_MORE_APPS}
        disabled={haveLoadedAllPages}
      >
        Load More Apps
      </LoadMoreButton>
    </Container>
  </React.Fragment>
);

const Category = compose(
  fulfill(async ({ match }) => {
    const { categorySlug } = match.params;
    await getCategoryBySlug(categorySlug);
    const categoryId = await axios
      .get(`/service/app-directory/v1/categories?slug=${categorySlug}`)
      .then(({ data }) =>
        data.hasOwnProperty('data') && data.data.length > 0
          ? data.data[0].id
          : null,
      );
    if (categoryId !== null) {
      resetPagination();
      getFeaturedApps({ categoryIds: categoryId, featureStatus: 'featured' });
      getPaginatedCategoryApps(1, categoryId);
    }
  }),

  connect(store, (state, props) => {
    const { categorySlug } = props.match.params;

    return {
      category: state.categories.idsBySlug.hasOwnProperty(categorySlug)
        ? selectCategoryBySlug(state.categories, categorySlug)
        : null,
      featuredApps:
        Object.keys(state.apps.featuredAppIdsByCategoryId).length > 0
          ? selectFeaturedCategoryAppsByCategorySlug(state, categorySlug)
          : [],
      apps: state.apps.idsByPage
        .reduce((accumulator, current) => accumulator.concat(current), [])
        .map((id) => state.apps.byId[id]),
      paginationPage: state.paginationPage,
      haveLoadedAllPages: state.haveLoadedAllPages,
      isFetchingPaginatedApps: state.isFetchingPaginatedApps,
    };
  }),

  notNull(['category', 'apps'], () => null),
)(StatelessCategory);

export default Category;
