import React from 'react';

const brandProtectionSvg = (i) => {
  const id = "brand-protection@2x-a"+ i;
  const fillUrl = `url(#${id})`;
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="162"
    height="179"
    viewBox="0 0 162 179"
  >
    <defs>
      <linearGradient id={id} x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#38D4C4" />
        <stop offset="100%" stopColor="#00AEEF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g opacity=".75" transform="translate(9.412 72.53)">
        <polygon
          fill="#00A9C4"
          points="0 42.157 .235 45.314 73.588 87.471 73.353 83.471"
        />
        <polygon
          fill="#00A9C4"
          points="73.353 87.471 145.02 44.471 145.02 42.157 73.353 83.471"
        />
        <polygon
          fill={fillUrl}
          points="73.353 0 0 42.157 73.353 85.157 145.02 42.157"
        />
      </g>
      <g opacity=".8" transform="translate(.96 51)">
        <polygon
          fill="#00A9C4"
          points="73.353 42.843 76.039 42 76.039 127 73.353 128"
        />
        <polygon
          fill="#00A9C4"
          points="2.039 0 76.039 42 73.353 42.843 0 .686"
        />
        <polygon
          fill={fillUrl}
          points="0 .686 73.353 42.843 73.353 128 0 81.627"
        />
      </g>
      <g transform="translate(9.412 .53)">
        <polygon
          fill="#00A9C4"
          points="0 42.157 0 44.471 73.588 87.471 73.353 83.471"
        />
        <polygon
          fill="#00A9C4"
          points="73.353 87.471 145.02 44.471 145.02 42.157 73.353 83.471"
        />
        <polygon
          fill={fillUrl}
          points="73.353 0 0 42.157 73.353 85.157 145.02 42.157"
        />
      </g>
      <g opacity=".9" transform="translate(84.373 51.667)">
        <polygon
          fill="#00A9C4"
          points="76.725 1.529 73.627 .333 .627 41.333 3.373 42.843"
        />
        <polygon
          fill="#00A9C4"
          points=".627 41.333 .627 125.333 3.373 127.157 3.373 42.843"
        />
        <polygon
          fill={fillUrl}
          points="3.373 42.843 3.373 127.157 76.725 85.843 76.725 1.529"
        />
      </g>
    </g>
  </svg>
)};

const campaignEffectivenessSvg = (i) => {
  const id = "linearGradient-1"+ i;
  const fillUrl = `url(#${id})`;
  return (
  <svg
    width="404px"
    height="236px"
    viewBox="0 0 404 236"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={id}>
        <stop stopColor="#8BC832" offset="0%" />
        <stop stopColor="#2CD5C4" offset="100%" />
      </linearGradient>
    </defs>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-946.000000, -2230.000000)"
      >
        <g transform="translate(351.000000, 439.000000)">
          <g transform="translate(78.000000, 560.000000)">
            <g transform="translate(37.000000, 1227.000000)">
              <g>
                <g transform="translate(480.000000, 4.000000)">
                  <g>
                    <g
                      opacity="0.400000006"
                      transform="translate(0.000000, 148.000000)"
                    >
                      <polygon
                        fill="#00A9C4"
                        points="1.8189894e-12 42.1568627 1.8189894e-12 44.4705882 73.5882353 87.4705882 73.3529412 83.4705882"
                      />
                      <polygon
                        fill="#00A9C4"
                        points="73.3529412 87.4705882 145.019608 44.4705882 145.019608 42.1568627 73.3529412 83.4705882"
                      />
                      <polygon
                        fill={fillUrl}
                        points="73.3529412 0 0 42.1568627 73.3529412 85.1568627 145.019608 42.1568627"
                      />
                    </g>
                    <g
                      opacity="0.599999964"
                      transform="translate(86.000000, 99.000000)"
                    >
                      <polygon
                        fill="#00A9C4"
                        points="1.8189894e-12 42.1568627 1.8189894e-12 44.4705882 73.5882353 87.4705882 73.3529412 83.4705882"
                      />
                      <polygon
                        fill="#00A9C4"
                        points="73.3529412 87.4705882 145.019608 44.4705882 145.019608 42.1568627 73.3529412 83.4705882"
                      />
                      <polygon
                        fill={fillUrl}
                        points="73.3529412 0 0 42.1568627 73.3529412 85.1568627 145.019608 42.1568627"
                      />
                    </g>
                    <g
                      opacity="0.799999952"
                      transform="translate(172.000000, 49.000000)"
                    >
                      <polygon
                        fill="#00A9C4"
                        points="1.8189894e-12 42.1568627 1.8189894e-12 44.4705882 73.5882353 87.4705882 73.3529412 83.4705882"
                      />
                      <polygon
                        fill="#00A9C4"
                        points="73.3529412 87.4705882 145.019608 44.4705882 145.019608 42.1568627 73.3529412 83.4705882"
                      />
                      <polygon
                        fill={fillUrl}
                        points="73.3529412 0 0 42.1568627 73.3529412 85.1568627 145.019608 42.1568627"
                      />
                    </g>
                    <g transform="translate(258.000000, 0.000000)">
                      <polygon
                        fill="#00A9C4"
                        points="1.8189894e-12 42.1568627 1.8189894e-12 44.4705882 73.5882353 87.4705882 73.3529412 83.4705882"
                      />
                      <polygon
                        fill="#00A9C4"
                        points="73.3529412 87.4705882 145.019608 44.4705882 145.019608 42.1568627 73.3529412 83.4705882"
                      />
                      <polygon
                        fill={fillUrl}
                        points="73.3529412 0 0 42.1568627 73.3529412 85.1568627 145.019608 42.1568627"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)};

const socialIntegrationSvg = (i) => {
  const id = "linearGradient-2"+ i;
  const fillUrl = `url(#${id})`;
  return (
  <svg
    width="209px"
    height="169px"
    viewBox="0 0 209 169"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={id}>
        <stop stopColor="#7F4DBF" offset="0%" />
        <stop stopColor="#00AEEF" offset="100%" />
      </linearGradient>
    </defs>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-1097.000000, -1682.000000)"
      >
        <g transform="translate(351.000000, 439.000000)">
          <g transform="translate(78.000000, 560.000000)">
            <g transform="translate(0.000000, 638.000000)">
              <g transform="translate(1.000000, 0.000000)">
                <g>
                  <g>
                    <g transform="translate(667.000000, 45.000000)">
                      <g transform="translate(132.000000, 0.000000)">
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                      <g
                        opacity="0.7"
                        transform="translate(105.000000, 8.000000)"
                      >
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                      <g
                        opacity="0.6"
                        transform="translate(80.000000, 16.000000)"
                      >
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                      <g
                        opacity="0.5"
                        transform="translate(53.000000, 24.000000)"
                      >
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                      <g
                        opacity="0.4"
                        transform="translate(27.000000, 33.000000)"
                      >
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                      <g
                        opacity="0.3"
                        transform="translate(0.000000, 41.000000)"
                      >
                        <polygon
                          fill="#3107B6"
                          points="73.3529412 42.8431373 76.0392157 42 76.0392157 127 73.3529412 128"
                        />
                        <polygon
                          fill="#3107B6"
                          points="2.03921569 0 76.0392157 42 73.3529412 42.8431373 0 0.68627451"
                        />
                        <polygon
                          fill={fillUrl}
                          points="0 0.68627451 73.3529412 42.8431373 73.3529412 128 0 81.627451"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)};

export { brandProtectionSvg, campaignEffectivenessSvg, socialIntegrationSvg };
