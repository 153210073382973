import React from 'react';
import Helmet from 'react-helmet';

const Head = () => (
  <Helmet titleTemplate="%s - Social Media Marketing &amp; Management Dashboard - Hootsuite">
    {/* `charset` and `viewport` must come first to consistently ensure proper document rendering */}
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600;700&family=Open+Sans:wght@400;600;700&display=swap"
      rel="stylesheet"
    />
    <meta property="description" content="Hootsuite App Directory" />
    {/* Icons:  */}
    <link rel="icon" sizes="192x192" href="" />
    <link rel="apple-touch-icon" href="" />
    <link rel="mask-icon" href="" color="blue" />
    {/* Facebook Open Graph: */}
    <meta property="fb:app_id" content="123456789" />
    <meta property="og:url" content="https://apps.hootsuite.com" />
    <meta property="og:type" content="website" />
    <meta
      property="og:title"
      content="App Directory - Social Media Marketing &amp; Management Dashboard - Hootsuite"
    />
    <meta property="og:image" content="" />
    <meta property="og:description" content="Hootsuite App Directory" />
    <meta property="og:site_name" content="App Directory" />
    <meta property="og:locale" content="en_US" />
    <meta property="article:author" content="Hootsuite" />
    {/* Twitter Card: */}
    <meta name="twitter:card" content="Hootsuite App Directory" />
    <meta name="twitter:site" content="@hootsuite" />
    <meta name="twitter:creator" content="@hootsuite" />
    <meta name="twitter:url" content="https://apps.hootsuite.com" />
    <meta name="twitter:title" content="App Directory" />
    <meta name="twitter:description" content="Hootsuite App Directory" />
    <meta name="twitter:image" content="" />
    {/* Google Site Verification: */}
    <meta
      name="google-site-verification"
      content="TTJxyytJxoTs_D15KV_M2QxIPSaSnP8FEeFJq1yfwBc"
    />
    <title>App Directory</title>
  </Helmet>
);

export default Head;
